const config = {
  apiGateway: {
    REGION: 'us-west-2',
    INTERNAL_API_URL: 'https://gs8gmekay9.execute-api.us-west-2.amazonaws.com/prod',
    TEAM_API_URL: 'https://zm76ujahvh.execute-api.us-west-2.amazonaws.com/api'
  },
  reactApp: {
    VERSION: 'taxonomy-teams-1.10.2',
    HOSTNAME: 'https://teams-service.pas.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.pas.forwoodsafety.com',
    WEBSOCKET: 'wss://cktts421p0.execute-api.us-west-2.amazonaws.com/prod'
  },
};

export default config;
